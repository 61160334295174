export default {
  ACTIONS: "Actions",
  ADD: "Add",
  AGENT: "Agent",
  CUSTOMER: "Customer",
  SNO: "Sno.",
  NAME: "Name",
  EMAIL: "Email",
  NUMBER: "Number",
  STATUS: "Status",
  DATE: "Date",
  IMAGE: "image",
  AGENTS: "Agents",
  LANGUAGE: "Language",
  SERVICE_PROVIDER: "Service Provider",
  CATEGORY: "Category",
  SUB_CATEGORY: "SUb Category",
  DESCRIPTION: "Description",
  LAYOUT_DIRECTION: "ltr"
};
