import { Formik, Form } from "formik";
import TextField from "../Common/Fields/TextField";
import PasswordFieldWithToggle from "../Common/Fields/PasswordFieldWithToggle";
import { Constants } from "../Common/Constants";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { loginValues } from "../Common/Formik/InitialValues";
import { loginSchema } from "../Common/Formik/Schema";
import { loginUser } from "../../services/auth.services";
import { useDispatch } from "react-redux";
import { setToken, destroyToken } from "../../redux/slices/authSlice";
import { RoutesUrl } from "../Common/RoutesUrl";
import { useNavigate } from "react-router-dom";
import style from "../../styles/signIn.module.css";
import { useEffect } from "react";

const SignIn = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async (values) => {
		const response = await loginUser(values);
		if (response.status == true) {
			const result = response.data.data.token;
			dispatch(setToken(result));
		}
		navigate(RoutesUrl.dashboard);
	};

	useEffect(() => {
		dispatch(destroyToken());
	}, []);

	return (
		<div className={style.sign_block}>
			<div className="container">
				<div
					className={style.customRow}
				>
					<div className="col-lg-6 ">
						<Formik
							initialValues={loginValues}
							enableReinitialize={true}
							validationSchema={loginSchema}
							onSubmit={(values) => {
								handleSubmit(values);
							}}
						>
							{({ errors, touched, values }) => (
								<Form className={style.sign_form}>
									<h2 className="text-center fw-bold">
										{Constants.admin} {Constants.login}
									</h2>

									<TextField
										type="email"
										name="email"
										placeholder="name@example.com"
										autoComplete="off"
										className="form-control form-control-lg"
										label={Constants.email}
										errors={errors?.email ? errors?.email : undefined}
										touched={touched?.email}
									/>

									<div className="">
										<TextField
											type="password"
											name="password"
											component={PasswordFieldWithToggle}
											placeholder="***************"
											className="form-control form-control-lg"
											label={Constants.password}
										/>
									</div>
									<ButtonCustom label={Constants.login} />
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
