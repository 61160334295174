import React from "react";
import Language from "./Language";
import Logo from "../../assets/image/logo.png";
import Admin from "../../assets/image/admin.png";
import styles from "../../styles/header.module.css";

const Header = () => {
  return (
    <>
      <header className={styles.siteHeader}>
        <div className="container-fluid">
          <div className="d-flex  justify-content-between">
            <div className={styles.logo}>
              {/* <link href="#"> */}
              <img src={Logo} />
              {/* </link> */}
            </div>
            <div className={styles.menu}>
              <ul className="d-flex align-items-center">
                <li>
                  <Language />
                </li>
                <li>
                  <img src={Admin} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
