export const RoutesUrl = {
	signIn: "/sign-in",
	agent: "/agent",
	addAgent: "/add-agent",
	dashboard: "/dashboard",
	customer: "/users",
	category: "/category",
	categoryDetails: "/category-details",
	addCategory: "/add-category",
	editCategory: "/edit-category",
	subCategory: "/sub-category",
	subCategoryDetails: "/sub-category-details",
	serviceProvider: "/service-provider",
	editSubCategory: "/edit-sub-category",
	addSubCategory: "/add-sub-category",
	userDetails: "/user-details"
};
