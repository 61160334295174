import { useLocation } from "react-router-dom";
import CardBlock from "../Common/UiModel/CardBlock";

const CategoryDetails = () => {
    const { state } = useLocation();

    return (
        <div>CategoryDetails

            <CardBlock props={state} />

        </div>
    )
}

// eslint-disable-next-line eol-last
export default CategoryDetails