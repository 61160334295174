import axios from "axios";
import { Constants } from "../components/Common/Constants";
import { API_URL } from "../config";
import { RoutesUrl } from "../components/Common/RoutesUrl";

class HttpService {
	defaultOptions = () => {
		return {
			baseURL: API_URL,
			headers: {
				Authorization: `Bearer ${localStorage.getItem(Constants.accessToken)}`
			}
		};
	};

	successResult = (res) => {
		if (res.data && res.data.status === true && res.data.statusCode === 200) {
			return { status: true, data: res.data };
		} else {
			return { status: false, data: Constants.somethingWentWrong };
		}
	};

	failureResult = (err) => {
		console.log('logout');
		if (err.response.status === 401) {
			console.log('logout');
			localStorage.clear();
			window.location = RoutesUrl.signIn;
		}
		const data = { status: false };
		try {
			data.error = err.message;
			if (
				err.response &&
        err.response.data &&
        err.response.data.status === false
			) {
				data.error = err.response.data.message;
			}
			return data;
		} catch (error) {
			data.error = error.message;
			return data;
		}
	};

	get = async (url, options = {}) => {
		try {
			const res = await axios.get(url, {
				...this.defaultOptions(),
				...options
			});
			return this.successResult(res);
		} catch (err) {
			return this.failureResult(err);
		}
	};

	post = async (url, data, options = {}) => {
		try {
			const res = await axios.post(url, data, {
				...this.defaultOptions(),
				...options
			});
			return this.successResult(res);
		} catch (err) {
			return this.failureResult(err);
		}
	};

	put = async (url, data, options = {}) => {
		try {
			const res = await axios.put(url, data, {
				...this.defaultOptions(),
				...options
			});
			return this.successResult(res);
		} catch (err) {
			return this.failureResult(err);
		}
	};

	patch = async (url, data, options = {}) => {
		try {
			const res = await axios.patch(url, data, {
				...this.defaultOptions(),
				...options
			});
			return this.successResult(res);
		} catch (err) {
			return this.failureResult(err);
		}
	};

	delete = async (url, data, options = {}) => {
		try {
			const res = await axios.delete(url, {
				...this.defaultOptions(),
				data,
				...options
			});
			return this.successResult(res);
		} catch (err) {
			return this.failureResult(err);
		}
	};
}

const httpServiceInstance = new HttpService();

export default httpServiceInstance;
