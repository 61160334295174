import { useEffect, useState } from "react";
import httpServiceInstance from "../HttpService";

const useFetch = (url, options) => {
	const [data, setData] = useState({ count: 0, data: [] });

	const fetch = async () => {
		try {
			const response = await httpServiceInstance.get(url, { params: options });
			if (response?.status)
				setData({
					count: response?.data?.data?.count,
					data: response?.data?.data?.data
				});
		} catch (error) {
			console.log("ERROR==>", error);
		}
	};

	useEffect(() => {
		fetch();
	}, [options]);

	return data;
};

export default useFetch;
