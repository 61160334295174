import React from 'react'
import { useLocation } from 'react-router-dom';
import CardBlock from '../Common/UiModel/CardBlock';

const SubCategoryDetails = () => {
    const { state } = useLocation();

  return (
    <div>SubCategoryDetails
            <CardBlock props={state} />

    </div>
  )
}

// eslint-disable-next-line eol-last
export default SubCategoryDetails