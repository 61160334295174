import { ApiUrl } from "../components/Common/ApiUrl";
import { Constants } from "../components/Common/Constants";
import HttpService from "../utils/HttpService";

export const loginUser = async (data) => {
	const response = await HttpService.post(ApiUrl.LOGIN, data);
	if (response.status === true) {
		const userData = response.data.data;
		localStorage.setItem(Constants.accessToken, userData.token);
	}
	return response;
};
