export default {
  ACTIONS: "أجراءات",
  ADD: "يضيف",
  AGENT: "عامل",
  CUSTOMER: "عميل",
  SNO: "سنو.",
  NAME: "اسم",
  EMAIL: "بريد إلكتروني",
  NUMBER: "رقم",
  STATUS: "حالة",
  DATE: "تاريخ",
  IMAGE: "صورة",
  AGENTS: "عملاء",
  LANGUAGE: "لغة",
  SERVICE_PROVIDER: "مقدم الخدمة",
  CATEGORY: "فئة",
  SUB_CATEGORY: "تصنيف فرعي",
  DESCRIPTION: "وصف",
  LAYOUT_DIRECTION: "rtl"
};
