import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '../Common/Fields/TextField';
import { Constants } from '../Common/Constants';
import { agentSchema } from '../Common/Formik/Schema';
import ButtonCustom from '../Common/Fields/ButtonCustom';
import PhoneInput from 'react-phone-input-2';
import PasswordFieldWithToggle from '../Common/Fields/PasswordFieldWithToggle';
import { success } from '../Common/UiModel/AlertMessage';
import { RoutesUrl } from '../Common/RoutesUrl';
import { agentAdd } from '../../services/agent.services';

const AgentForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isUpdate] = useState(!!state);

  const [initialValues] = useState(
    isUpdate
      ? state
      : {
        name: "",
        email: "",
        phoneNo: "",
        password: ""
      }
  );

  const handleSubmit = async (values) => {
    try {
      const response = await agentAdd(values);
      navigate(RoutesUrl.agent);
      success(Constants.success)
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }

  const handleUpdate = () => {

  }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={agentSchema}
        onSubmit={(values) => {
          isUpdate ? handleUpdate(values) : handleSubmit(values);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <h2 className="text-center fw-bold">
              {isUpdate ? Constants.update : Constants.add}{" "}
              {Constants.agent}
            </h2>

            <TextField
              name="name"
              label="Name"
              placeholder="Agent Name"
              autoComplete="off"
              className="form-control form-control-lg"
              errors={errors?.name ? errors?.name : undefined}
              touched={touched?.name}
            />

            <TextField
              name="email"
              label="Email"
              placeholder="Email ID"
              autoComplete="off"
              className="form-control form-control-lg"
              errors={errors?.email ? errors?.email : undefined}
              touched={touched?.email}
            />

            <PhoneInput
              country="in"
              name="phoneNo"
              onChange={(e) => {
                setFieldValue("phoneNo", e);
              }}
              errors={touched?.phoneNo ? errors?.phoneNo : undefined}
              touched={touched?.phoneNo}
            />

            <TextField
              name="password"
              label="Password"
              placeholder="Password"
              autoComplete="off"
              className="form-control form-control-lg"
              errors={errors?.password ? errors?.password : undefined}
              touched={touched?.password}
            />
            <ButtonCustom label={isUpdate ? Constants.update : Constants.add} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AgentForm
