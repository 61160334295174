import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "../../components/Common/Constants";

const initialState = {
	token: localStorage.getItem(Constants.accessToken) || null
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken (state, value) {
			state.token = value.payload;
		},

		destroyToken (state) {
			state.token = null;
			localStorage.removeItem(Constants.accessToken);
		}
	}
});

export const { setToken, destroyToken } = authSlice.actions;
export default authSlice.reducer;
