import * as Yup from "yup";
import { Constants } from "../Constants";

export const loginSchema = Yup.object().shape({
	email: Yup.string().email().required(Constants.required),
	password: Yup.string().required(Constants.required)
});

export const categorySchema = Yup.object().shape({
	name: Yup.string().required(Constants.required),
	description: Yup.string().required(Constants.required)
	// image: Yup.string().required(Constants.required)
});

export const subCategorySchema = Yup.object().shape({
	categoryId: Yup.string().required(Constants.required),
	name: Yup.string().required(Constants.required),
	description: Yup.string().required(Constants.required)
});

export const agentSchema = Yup.object().shape({
	name: Yup.string().required(Constants.required),
	email: Yup.string().email().required(Constants.required),
	phoneNo: Yup.string().required(Constants.required),
	password: Yup.string().required(Constants.required)
});
