import { Field } from "formik";

function TextField (props) {
	let className = props.className;
	if (!className) {
		className = "form-control";
	}

	return (
		<div className="form-group">
			<div className="mb-2">
				<label className="form-label">{props.label} </label>
				<Field {...props} className={className} />
				{(props.errors || props.touched) && (
					<p className="text-danger">{props.errors}</p>
				)}
			</div>
		</div>
	);
}

export default TextField;
