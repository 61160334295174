import { useLocation } from "react-router-dom";
import CardBlock from "../Common/UiModel/CardBlock";

const CustomerDetails = () => {
    const { state } = useLocation();
    return (
        <div>
            <h2>Customer Details</h2>
            <br/>
            <CardBlock props={state}/>
        </div>
    )
}

// eslint-disable-next-line eol-last
export default CustomerDetails