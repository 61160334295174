import User from "../../assets/image/user.png";
import Revenue from "../../assets/image/revenue.png";

import commonStyle from "../../styles/common.module.css";

const Dashboard = () => {
	return (
		<>
			<div className={`${commonStyle.admin_dashboard}`}>
				<h1>Dashboard</h1>
				<div className="row">
					<div className="col-lg-6">
						<div className={commonStyle.inner_row}>
							<div className={commonStyle.item}>
								<div className="">
									<h5>Total Customers</h5>
									<h3>59</h3>
									<p>
										<span>35%</span>Since last week
									</p>
								</div>
								<img src={User} />
							</div>
							<div className={commonStyle.item}>
								<div className="">
									<h5>New Bookings</h5>
									<h3>4</h3>
									<p>
										<span>35%</span>Since last week
									</p>
								</div>
								<img src={User} />
							</div>
							<div className={commonStyle.item}>
								<div className="">
									<h5>Total revenue</h5>
									<h3>$250</h3>
									<p>
										<span>35%</span>Since last week
									</p>
								</div>
								<img src={User} />
							</div>
							<div className={commonStyle.item}>
								<div className="">
									<h5>Total Bookings</h5>
									<h3>89</h3>
									<p>
										<span>35%</span>Since last week
									</p>
								</div>
								<img src={User} />
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="image">
							<img src={Revenue} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
