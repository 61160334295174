import { Link, useLocation } from "react-router-dom";
import style from "../../styles/Sidebar.module.css";
import { RoutesUrl } from "../Common/RoutesUrl";

const SidebarData = [
	{
		name: "Dashboard",
		link: RoutesUrl.dashboard,
		icon: "fa fa-dashboard"
	},
	{
		name: "Customer",
		link: RoutesUrl.customer,
		icon: "fa fa-users"
	},
	{
		name: "Category",
		link: RoutesUrl.category,
		icon: "fa fa-list-ul"
	},
	{
		name: "Sub Category",
		link: RoutesUrl.subCategory,
		icon: "fa fa-list"
	},
	{
		name: "Service Provider",
		link: RoutesUrl.serviceProvider,
		icon: "fa fa-list"
	},
	{
		name: "Agents",
		link: RoutesUrl.agent,
		icon: "fa fa-list"
	},
	{
		name: "Logout",
		link: RoutesUrl.signIn,
		icon: "fa  fa-sign-out"
	}
];

const Sidebar = ({ isCollapse, setIsCollapse }) => {
	const { pathname } = useLocation();

	return (
		<>
			<div className={`${style.sideBar} ${isCollapse ? style.xyz : ""}`}>
				<ul className="icon">
					{SidebarData?.map((ele) => (
						<li className={ele.link === pathname && style.activeMenu}>
							<Link to={ele.link}>
								<i className={ele.icon}></i>
								{ele.name}
							</Link>
						</li>
					))}
				</ul>
				<div
					className={style.sideBarOpen}
					onClick={() => setIsCollapse((prev) => !prev)}
				>
					<i className="fa fa-angle-left"></i>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
