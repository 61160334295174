export const ApiUrl = {
	AGENT: {
		GET: "admin/get/agents",
		STATUS_UPDATE: "admin/block/unblock/agent",
		CREATE: "admin/create/agent"
	},
	CATEGORIES_ADD: "admin/create/category",
	CATEGORIES_GET: "admin/get/categories",
	CATEGORIES_UPDATE: "admin/update/category",
	CATEGORIES_STATUS_UPDATE: "admin/block/unblock/category",
	LOGIN: "admin/login",
	SUB_CATEGORIES_ADD: "admin/create/sub/category",
	SUB_CATEGORIES_GET: "admin/get/sub/categories",
	SUB_CATEGORIES_UPDATE: "admin/update/sub/category",
	SUB_CATEGORIES_STATUS_UPDATE: "admin/block/unblock/sub/category",
	USERS_GET: "admin/get/users",
	USERS_STATUS_UPDATE: "admin/block/unblock/user",
	VENDOR: {
		GET: "admin/get/vendors",
		STATUS_UPDATE: "admin/block/unblock/vendor"
	}
};
