import { Routes, Route } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import SignIn from "../components/Auth/SignIn";
import { RoutesUrl } from "../components/Common/RoutesUrl";
import Customer from "../components/Customer";
import Category from "../components/Category";
import SubCategory from "../components/SubCategory";
import CategoryForm from "../components/Category/CategoryForm";
import SubCategoryForm from "../components/SubCategory/SubCategoryForm";
import ServiceProvider from "../components/ServiceProvider";
import Agent from "../components/Agent";
import CustomerDetails from "../components/Customer/CustomerDetails";
import CategoryDetails from "../components/Category/CategoryDetails";
import SubCategoryDetails from "../components/SubCategory/SubCategoryDetails";
import AgentForm from "../components/Agent/AgentForm";

function MainRoutes () {
	return (
		<div>
			<Routes>
				<Route path="/" element={<Dashboard />} />
				<Route path={RoutesUrl.dashboard} element={<Dashboard />} />
				<Route path={RoutesUrl.signIn} element={<SignIn />} />
				<Route path={RoutesUrl.agent} element={<Agent />} />
				<Route path={RoutesUrl.addAgent} element={<AgentForm />} />
				<Route path={RoutesUrl.category} element={<Category />} />
				<Route path={RoutesUrl.addCategory} element={<CategoryForm />} />
				<Route path={RoutesUrl.editCategory} element={<CategoryForm />} />
				<Route path={RoutesUrl.customer} element={<Customer />} />
				<Route path={RoutesUrl.serviceProvider} element={<ServiceProvider />} />
				<Route path={RoutesUrl.subCategory} element={<SubCategory />} />
				<Route path={RoutesUrl.editSubCategory} element={<SubCategoryForm />} />
				<Route path={RoutesUrl.addSubCategory} element={<SubCategoryForm />} />
				<Route path={RoutesUrl.userDetails} element={<CustomerDetails />} />
				<Route path={RoutesUrl.categoryDetails} element={<CategoryDetails />} />
				<Route path={RoutesUrl.subCategoryDetails} element={<SubCategoryDetails />} />
			</Routes>
		</div>
	);
}

export default MainRoutes;
