import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../utils/HttpService";

export const agentUpdateStatus = async (id, data) => {
	try {
		const response = await httpServiceInstance.patch(
			ApiUrl.AGENT.STATUS_UPDATE + "?id=" + id,
			data
		);
		return response;
	} catch (error) {
		console.log("Error Update Status");
		throw new Error("An Error Occured Status Update");
	}
};

export const agentAdd = async (data) => {
	try {
		const response = await httpServiceInstance.post(
			ApiUrl.AGENT.CREATE,
			data
		);
		return response;
	} catch (error) {
		console.error("Error in  Agent Add:", error);
		throw error;
	}
};
