export const loginValues = {
	email: "",
	password: ""
};

export const subCategoryValues = {
	categoryId: "",
	name: "",
	description: ""
};
