import Swal from "sweetalert2";
import { Constants } from "../Constants";

export const error = (msg) => {
    return Swal.fire({
        title: Constants.oops,
        icon: "error",
        text: msg,
        timer: 2500
    });
};

export const success = (msg) => {
   return Swal.fire({
        title: Constants.success,
        text: msg,
        icon: "success",
        timer: 2000
    });
};

export const onDelete = () => {
    return Swal.fire({
        title: Constants.deleted,
        text: Constants.itemDeleted,
        icon: "success",
        timer: 1000
    });
};

export const deleteConfirmBox = (data) => {
    return Swal.fire({
        title: Constants.areYouSure,
        text: data?.text || Constants.noRevert,
        icon: "warning",
        showCancelButton: true
    });
};

export const confirmBox = (data) => {
    return Swal.fire({
        title: data?.msg,
        text: data?.text,
        icon: "warning",
        showCancelButton: true
    })
}
