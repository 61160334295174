import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../utils/HttpService";

export const vendorUpdateStatus = async (id, data) => {
	try {
		const response = await httpServiceInstance.patch(
			ApiUrl.VENDOR.STATUS_UPDATE + "?id=" + id,
			data
		);
		return response;
	} catch (error) {
		console.log("Error Update Status");
		throw new Error("An Error Occured Status Update");
	}
};
