import dayjs from "dayjs";
import { Constants } from "../Constants";

function CardBlock ({ props }) {
    // Card for Created At, Name, Email, Number
    const OrderDetailData = [
        {
            mainbgColor: "alert-success",
            bgColor: "bg-success",
            icon: "fa fa-shopping-cart fa-lg",
            name: Constants.createdAt,
            secondName: `${dayjs(props?.createdAt).format(
                Constants.dateFormat
            )} at ${dayjs(props?.createdAt).format(Constants.timeFormat)}`
        },
        {
            mainbgColor: "alert-danger",
            bgColor: "bg-danger",
            icon: "fa fa-user fa-lg",
            name: Constants.name,
            secondName: props?.name || props?.props_id?.name || Constants.na
        },
        {
            mainbgColor: "alert-warning",
            bgColor: "bg-warning",
            icon: "fa fa-envelope fa-lg",
            name: Constants.email,
            secondName: props?.email || props?.props_id?.email || Constants.na
        },
        {
            mainbgColor: "alert-info",
            bgColor: "bg-info",
            icon: "fa fa-phone-square fa-lg",
            name: Constants.number,
            secondName: `${props?.dial_code} ${props?.phoneNo}` || Constants.na
        }
    ];

    return (
        <div className="row g-3 mb-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
            {OrderDetailData.map((order, i) => {
                return (
                    <div key={"s" + i} className="col">
                        <div className={`${order.mainbgColor} alert mb-0 `}>
                            <div className="d-flex align-items-center">
                                <div
                                    className={`avatar rounded no-thumbnail ${order.bgColor} text-light`}
                                >
                                    <i className={order.icon}></i>
                                </div>
                                <div className="flex-fill ms-3 text-truncate">
                                    <div className="h5 mb-0">{order.name}</div>
                                    <span className="">{order.secondName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
export default CardBlock;
