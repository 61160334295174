import {
  getCategoryOptions,
  subCategoryAdd,
  subCategoryEdit
} from "../../services/subCategory.services";
import { Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { subCategorySchema } from "../Common/Formik/Schema";
import { Constants } from "../Common/Constants";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { RoutesUrl } from "../Common/RoutesUrl";
import { useState, useEffect } from "react";

const SubCategoryForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isUpdate] = useState(!!state);
  const [CategoryList, setCategoryList] = useState();

  const [initialValues] = useState(
    isUpdate
      ? state
      : {
        categoryId: "",
        name: "",
        description: "",
        image: ""
      }
  );

  useEffect(() => {
    const optionList = async () => {
      const response = await getCategoryOptions();
      if (response.status) {
        setCategoryList(response?.data?.data?.data);
      }
    };
    optionList();
  }, []);


  const handleSubmit = async (values) => {
    try {
      values.image =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT107I-BRr4qxk63zl_EXvHSUr5UkZCcBy3iXXL--MWTJ9uX4xHZWiaykAsz74dEjRnKGA&usqp=CAU";

      const response = await subCategoryAdd(values);
      navigate(RoutesUrl.subCategory);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleUpdate = async (values) => {
    const data = {
      subCategoryId: values._id,
      categoryId: values.categoryId,
      name: values.name,
      description: values.description,
      image: values.image
    }
    try {
      const response = await subCategoryEdit(state._id, data);
      navigate(RoutesUrl.subCategory);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={subCategorySchema}
        onSubmit={(values) => {
          isUpdate ? handleUpdate(values) : handleSubmit(values);
        }}
      >
        {({ errors, touched, values }) => (
          <Form>
            <h2 className="text-center fw-bold">
              {isUpdate ? Constants.update : Constants.add}{" "}
              {Constants.subCategory}
            </h2>

            <Field className="form-control" name="categoryId" as="select">
              <option value=''>Select a Category</option>
              {CategoryList?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </Field>
            {errors.categoryId && touched.categoryId
              ? (
                <div className="text-danger">{errors.categoryId}</div>
              )
              : null}

            <TextField
              name="name"
              placeholder="Sub Category Name"
              autoComplete="off"
              className="form-control form-control-lg"
              errors={errors?.name ? errors?.name : undefined}
              touched={touched?.name}
            />

            <TextField
              name="description"
              placeholder="Description"
              autoComplete="off"
              className="form-control form-control-lg"
              errors={errors?.description ? errors?.description : undefined}
              touched={touched?.description}
            />

            <ButtonCustom label={isUpdate ? Constants.update : Constants.add} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubCategoryForm;
