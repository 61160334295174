import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { categorySchema } from "../Common/Formik/Schema";
import { Constants } from "../Common/Constants";
import TextField from "../Common/Fields/TextField";
import ButtonCustom from "../Common/Fields/ButtonCustom";
import { RoutesUrl } from "../Common/RoutesUrl";
import { useNavigate } from "react-router";
import { categoryAdd, categoryEdit } from "../../services/category.services";
import styles from "../../styles/Category.module.css";
import { useLocation } from "react-router-dom";
import { success } from "../Common/UiModel/AlertMessage";

const CategoryForm = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const [isUpdate] = useState(!!state);
	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();

	const [initialValues] = useState(
		isUpdate
			? state
			: {
				name: "",
				description: "",
				image: ""
			}
	);

	useEffect(() => {
		if (!selectedFile) {
			setPreview(undefined);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		// free memory when this component is unmounted
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile]);

	const onSelectFile = (e) => {
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		setSelectedFile(e.target.files[0]);
	};

	const handleSubmit = async (values) => {
		try {
			values.image =
				"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT107I-BRr4qxk63zl_EXvHSUr5UkZCcBy3iXXL--MWTJ9uX4xHZWiaykAsz74dEjRnKGA&usqp=CAU";
			const response = await categoryAdd(values);
			navigate(RoutesUrl.category);
			success(Constants.success)
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	const handleUpdate = async (values) => {
    const data = {
      categoryId: values._id,
      name: values.name,
      description: values.description,
      image: values.image
    }
		try {
			const response = await categoryEdit(state._id, data);
			navigate(RoutesUrl.category);
		} catch (error) {
			console.error("An error occurred:", error);
		}
	};

	return (
		<div className={styles.categoryBlock}>
			<Formik
				initialValues={initialValues}
				enableReinitialize={true}
				validationSchema={categorySchema}
				onSubmit={(values) => {
					isUpdate ? handleUpdate(values) : handleSubmit(values);
				}}
			>
				{({ errors, touched, values }) => (
					<Form>
						<h2 className="text-center fw-bold">
							{isUpdate ? Constants.update : Constants.add} {Constants.category}
						</h2>
						<TextField
							name="name"
							placeholder="Category Name"
							autoComplete="off"
							className="form-control form-control-lg"
							errors={errors?.name ? errors?.name : undefined}
							touched={touched?.name}
						/>
						<TextField
							name="description"
							placeholder="Description"
							autoComplete="off"
							className="form-control form-control-lg"
							errors={errors?.description ? errors?.description : undefined}
							touched={touched?.description}
						/>
						<input type="file" name="image" onChange={onSelectFile} />
						<span className="text-danger">
							{errors?.image ? errors?.image : undefined}
						</span>
						{selectedFile && <img src={preview} />}
						<ButtonCustom label={isUpdate ? Constants.update : Constants.add} />
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default CategoryForm;
