import { createSlice } from "@reduxjs/toolkit";
import { Lang } from "../../components/Common/Constants";

const langSlice = createSlice({
  name: "lang",
  initialState: Lang.English,
  reducers: {
    setLang: (state, value) => {
      return Object.values(Lang)?.includes(value.payload)
        ? (state = value.payload)
        : (state = Lang.English);
    }
  }
});

export const { setLang } = langSlice.actions;
export default langSlice.reducer;
