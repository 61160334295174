import Dropdown from "react-bootstrap/Dropdown";
import { Lang } from "../Common/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "./../../redux/slices/langSlice";
import Locale from "../../lang";

const Language = () => {
  const lng = useSelector((val) => val.lang);
  const dispatch = useDispatch();

  const handleSelect = (eventKey) => {
    dispatch(setLang(eventKey));
  };

  return (
    <Dropdown onSelect={handleSelect}>
      <Dropdown.Toggle variant="" id="dropdown-basic">
        {Locale[lng]?.LANGUAGE}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {Object.entries(Lang)?.map(([key, value]) => (
          <Dropdown.Item key={key} eventKey={value}>
            {key}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Language;
