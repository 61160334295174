import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../utils/HttpService";

export const subCategoryAdd = async (data) => {
	try {
		const response = await httpServiceInstance.post(
			ApiUrl.SUB_CATEGORIES_ADD,
			data
		);
		return response;
	} catch (error) {
		console.error("Error in SubcategoryAdd:", error);
		throw error;
	}
};

export const subCategoryEdit = async (id, data) => {
	try {
		const response = await httpServiceInstance.patch(
			ApiUrl.SUB_CATEGORIES_UPDATE + "?id=" + id,
			data
		);
		return response;
	} catch (error) {
		console.error("Error in Sub-categoryEdit:", error);
		throw new Error("An error occurred during sub-category edit.");
	}
};

export const subCategoryUpdateStatus = async (id, data) => {
	try {
		const response = await httpServiceInstance.patch(
			ApiUrl.SUB_CATEGORIES_STATUS_UPDATE + "?id=" + id,
			data
		);
		return response;
	} catch (error) {
		console.log("Error Update Status");
		throw new Error("An Error Occured Status Update");
	}
};

export const getCategoryOptions = async () => {
	try {
		const response = await httpServiceInstance.get(ApiUrl.CATEGORIES_GET);
		return response;
	} catch (error) {
		console.log("Error Update Status");
		throw new Error("An Error Occured Status Update");
	}
};
