import React from "react";
import ReactPaginate from "react-paginate";

const ReactPagination = (props) => {
	const pageCount = Math.ceil(props?.total / props?.itemsPerPage);

	const handlePageClick = (event) => {
		const newOffset = (event?.selected * props?.itemsPerPage) % props?.total;
		props.newOffset(newOffset);
	};

	return (
		<div className="react-pagination-btm">
			<ReactPaginate
				breakLabel="..."
				nextLabel=">"
				onPageChange={handlePageClick}
				pageRangeDisplayed={2}
				pageCount={pageCount}
				previousLabel="<"
				renderOnZeroPageCount={null}
			/>
		</div>
	);
};

export default ReactPagination;
