import { useState } from "react";
import userimage from "../../assets/image/tabeluser.png";
import ReactPagination from "../Common/Fields/ReactPagination";
import { ApiUrl } from "../Common/ApiUrl";
import useFetch from "../../utils/customHooks/useFetch";
import Lang from "../../lang";
import { useSelector } from "react-redux";
import { agentUpdateStatus } from "../../services/agent.services";
import dayjs from "dayjs";
import { Constants } from "../Common/Constants";
import { confirmBox } from "../Common/UiModel/AlertMessage";
import { useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";

const Agent = () => {
  const navigate = useNavigate();
  const lng = useSelector((val) => val.lang);

  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const data = useFetch(ApiUrl.AGENT.GET, filter);

  const handleOffset = (newOff) => {
    setFilter({ limit: 10, offset: newOff });
  };

  const handleStatusClick = async (val) => {
    const popMsg = { text: Constants.statusChangeMsg }
    confirmBox(popMsg).then((result) => {
      if (result.isConfirmed) {
        const data = {
          vendorId: val._id,
          isBlocked: !val.isBlocked
        };
        changeStatus(data)
      }
    })
  };

  const changeStatus = async (data) => {
    if (data) {
      const response = await agentUpdateStatus(data._id, data);
    } else {
      console.log("error at status update");
    }
  }

  return (
    <>
      <div className="tabel_page p-3" dir={Lang[lng].LAYOUT_DIRECTION}>
        <div className=" d-flex align-items-center justify-content-between">
          <h1>{Lang[lng]?.AGENTS}</h1>
          <span
            className="addCta"
            onClick={() => navigate(RoutesUrl.addAgent)}
          >
            <i className="fa fa-plus"></i>
            {Lang[lng].ADD}
          </span>
        </div>
        <table className="table table-centered table-nowrap table-striped dataTable no-footer">
          <thead>
            <tr>
              <th scope="col">{Lang[lng]?.SNO}</th>
              <th scope="col">{Lang[lng]?.IMAGE}</th>
              <th scope="col">{Lang[lng]?.NAME}</th>
              <th scope="col">{Lang[lng]?.EMAIL}</th>
              <th scope="col">{Lang[lng]?.NUMBER}</th>
              <th scope="col">{Lang[lng]?.STATUS}</th>
              <th scope="col">{Lang[lng]?.DATE}</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((val, i) => (
              <tr key={val._id}>
                <th scope="row">{++i}</th>
                <td>
                  <img src={val.image || userimage} />
                </td>
                <td>{val.name}</td>
                <td>{val.email}</td>
                <td dir="ltr" className="d-flex justify-content-center">{`${val.countryCode} ${val.phoneNo}`}</td>
                <td>
                  <span
                    className={val.isBlocked ? "active" : "inactive"}
                    onClick={() => handleStatusClick(val)}
                  ></span>
                </td>
                <td>{dayjs(val.createdAt).format(Constants.dateFormat)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center">
        <ReactPagination
          itemsPerPage="10"
          total={data.count}
          newOffset={handleOffset}
        />
      </div>
    </>
  );
};

export default Agent;
