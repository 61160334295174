function ButtonCustom (props) {
	let className = props.className;
	let type = props.type;

	if (!className) {
		className = "ctaBtn";
	}
	if (!type) {
		type = "submit";
	}

	return (
		<div className="col-12 text-center mt-4">
			<button {...props} className={className} type={type}>
				{props.label}
			</button>
		</div>
	);
}

export default ButtonCustom;
