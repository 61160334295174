import { ApiUrl } from "../components/Common/ApiUrl";
import httpServiceInstance from "../utils/HttpService";

export const categoryAdd = async (data) => {
	try {
		const response = await httpServiceInstance.post(
			ApiUrl.CATEGORIES_ADD,
			data
		);
		return response;
	} catch (error) {
		console.error("Error in categoryAdd:", error);
		throw error;
	}
};

export const categoryEdit = async (id, data) => {
	try {
		const response = await httpServiceInstance.patch(
			ApiUrl.CATEGORIES_UPDATE + "?id=" + id,
			data
		);
		return response;
	} catch (error) {
		console.error("Error in categoryEdit:", error);
		throw new Error("An error occurred during category edit.");
	}
};

export const categoryUpdateStatus = async (id, data) => {
	try {
		const response = await httpServiceInstance.patch(
			ApiUrl.CATEGORIES_STATUS_UPDATE + "?id=" + id,
			data
		);
		return response;
	} catch (error) {
		console.log("Error Update Status");
		throw new Error("An Error Occured Status Update");
	}
};
