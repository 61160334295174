import { useState } from "react";
import userimage from "../../assets/image/tabeluser.png";
import ReactPagination from "../Common/Fields/ReactPagination";
import { ApiUrl } from "../Common/ApiUrl";
import useFetch from "../../utils/customHooks/useFetch";
import { useSelector } from "react-redux";
import Lang from "../../lang";
import { Link, useNavigate } from "react-router-dom";
import { RoutesUrl } from "../Common/RoutesUrl";
import dayjs from "dayjs";
import { Constants } from "../Common/Constants";

const Customer = () => {
  const navigate = useNavigate();
  const lng = useSelector((val) => val.lang);

  const [filter, setFilter] = useState({ limit: 10, offset: 0 });
  const data = useFetch(ApiUrl.USERS_GET, filter);

  const handleOffset = (newOff) => {
    setFilter({ limit: 10, offset: newOff });
  };

  return (
    <>
      <div className="tabel_page p-3" dir={Lang[lng].LAYOUT_DIRECTION}>
        <h1>{Lang[lng]?.CUSTOMER}</h1>
        <table className="table table-centered table-nowrap table-striped dataTable no-footer">
          <thead>
            <tr>
              <th scope="col">{Lang[lng]?.SNO}</th>
              <th scope="col">{Lang[lng]?.NAME}</th>
              <th scope="col">{Lang[lng]?.EMAIL}</th>
              <th scope="col">{Lang[lng]?.NUMBER}</th>
              <th scope="col">{Lang[lng]?.STATUS}</th>
              <th scope="col">{Lang[lng]?.DATE}</th>
              <th scope="col">{Lang[lng]?.IMAGE}</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((val, i) => (
              <tr key={val._id}>
                <th scope="row">{++i}</th>
                <td>
                  <Link
                    to={RoutesUrl.userDetails}
                    state={val}
                  >
                    {val.name}
                  </Link>
                </td>
                <td>{val.email}</td>
                <td>{`${val.countryCode} ${val.phoneNo}`}</td>
                <td>
                  <span
                    className={val.isBlocked ? "inactive" : "active"}
                  ></span>
                </td>
                <td>{dayjs(val.createdAt).format(Constants.dateFormat)}</td>
                <td>
                  <img src={val.image || userimage} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center">
        <ReactPagination
          itemsPerPage="10"
          total={data.count}
          newOffset={handleOffset}
        />
      </div>
    </>
  );
};

export default Customer;
