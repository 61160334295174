export const Constants = {
  accessToken: "accessToken",
  accountStatus: "Account Status",
  add: "Add",
  admin: "Admin",
  agent: "Agent",
  category: "Category",
  createdAt: "Created At",
  dateFormat: "YYYY-MM-DD",
  deleted: "Deleted",
  email: "Email",
  id: "ID",
  itemDeleted: "Item has been deleted",
  image: "Image",
  login: "Login",
  na: "Not Available",
  name: "Name",
  noRevert: `You won't be able to revert this!`,
  number: "Number",
  oops: "Oops!",
  password: "Password",
  somethingWentWrong: "Something Went Wrong",
  subCategory: "Sub Category",
  success: "Success",
  statusChangeMsg: "Do you want to change status?",
  timeFormat: "hh:mm A",
  update: "Update",
  required: "This field cannot be empty"
};

export const Lang = {
  English: "en",
  Arabic: "ar"
};
