import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import userimage from "../../assets/image/tabeluser.png";
import { ApiUrl } from "../Common/ApiUrl";
import useFetch from "../../utils/customHooks/useFetch";
import ReactPagination from "../Common/Fields/ReactPagination";
import { RoutesUrl } from "../Common/RoutesUrl";
import { subCategoryUpdateStatus } from "../../services/subCategory.services";
import Lang from "../../lang";
import { useSelector } from "react-redux";

const SubCategory = () => {
	const lng = useSelector((val) => val.lang);
	const navigate = useNavigate();
	const [filter, setFilter] = useState({ limit: 10, offset: 0 });
	const data = useFetch(ApiUrl.SUB_CATEGORIES_GET, filter);

	const handleOffset = (newOff) => {
		setFilter({ limit: 10, offset: newOff });
	};

	const handleDeleteClick = (id) => {
		console.log("Delete clicked");
	};

	const handleStatusClick = async (val) => {
		if (val._id) {
			const data = {
				subCategoryId: val._id,
				isBlocked: !val.isBlocked
			};
			const response = await subCategoryUpdateStatus(val._id, data);
			// --- Success Pop Up here
		} else {
			console.log("error at status update");
		}
	};

	return (
		<>
			<div className="tabel_page p-3" dir={Lang[lng].LAYOUT_DIRECTION}>
				<div className=" d-flex align-items-center justify-content-between">
					<h1>{Lang[lng]?.SUB_CATEGORY}</h1>
					<span
						className="addCta"
						onClick={() => navigate(RoutesUrl.addSubCategory)}
					>
						<i className="fa fa-plus"></i>
						{Lang[lng].ADD}
					</span>
				</div>
				<table className="table table-centered table-nowrap table-striped dataTable no-footer">
					<thead>
						<tr>
							<th scope="col">{Lang[lng]?.SNO}</th>
							<th scope="col">{Lang[lng]?.IMAGE}</th>
							<th scope="col">{Lang[lng]?.NAME}</th>
							<th scope="col">{Lang[lng]?.DESCRIPTION}</th>
							<th scope="col">{Lang[lng]?.STATUS}</th>
							<th scope="col">{Lang[lng]?.ACTIONS}</th>
						</tr>
					</thead>
					<tbody>
						{data?.data?.map((val, i) => (
							<tr key={val._id}>
								<th scope="row">{++i}</th>
								<td>
									<img src={val.image || userimage} />
								</td>
								<td>
									<Link
										to={RoutesUrl.subCategoryDetails}
										state={val}
									>
										{val.name}
									</Link>
								</td>
								<td>{val.description}</td>
								<td>
									<span
										className={val.isBlocked ? "active" : "inactive"}
										onClick={() => handleStatusClick(val)}
									></span>
								</td>
								<td>
									<ul className="actionsIcon ">
										<li className="d-inline-block ">
											<Link to={RoutesUrl.editSubCategory} state={val}>
												<i className="fa fa-edit"></i>
											</Link>
										</li>
										<li className="d-inline-block ">
											<i
												className="fa fa-trash"
												onClick={() => handleDeleteClick(val._id)}
											></i>
										</li>
									</ul>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<div className="d-flex justify-content-center">
				<ReactPagination
					itemsPerPage="10"
					total={data.count}
					newOffset={handleOffset}
				/>
			</div>
		</>
	);
};

export default SubCategory;
