import { useLocation } from "react-router-dom";

import "./App.css";

import MainRoutes from "./routes/MainRoutes";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar/Sidebar";

import commonStyle from "./styles/common.module.css";
import { RoutesUrl } from "./components/Common/RoutesUrl";
import { useState } from "react";

function App () {
	const { pathname } = useLocation();

	const excludeSidebar = pathname === RoutesUrl.signIn;

	const [isCollapse, setIsCollapse] = useState(false);

	return (
		<div className={"pageContent "}>
			{!excludeSidebar && (
				<>
					<Header />
					<Sidebar isCollapse={isCollapse} setIsCollapse={setIsCollapse} />
				</>
			)}
			<div
				className={
					excludeSidebar
						? ""
						: `${commonStyle.main} ${
							isCollapse ? commonStyle.activeSidebar : ""
						}`
				}
			>
				<MainRoutes activekey={pathname} />
			</div>
		</div>
	);
}

export default App;
