import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import langSlice from "../slices/langSlice";

const rootReducer = combineReducers({
	auth: authReducer,
	lang: langSlice
});

export default rootReducer;
